import React, { Component } from "react";
import { StoreState } from "../../reducers";
import {
  FC_GetLocationsDetails,
  GenderEnum,
  LocationAPI,
  ServicesStore,
  System,
} from "../../actions";
import { connect } from "react-redux";
import {
  CustomerUpdateData,
  FC_SubmitCustomerInfo,
} from "../../actions/customers.action";
import { COMPLAINT_TYPE } from "../../containers/SubmitComplaint/SelectComplaintType";
import Input from "../Fragments/Input";
import Select from "../Fragments/Select";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import SelectCustom from "../SelectCustom/SelectCustom";
import Alert, { AlertType } from "../Alert/Alert";
import { GenerateLangText } from "../../utils/functions";
import { validateEmail } from "../../utils/validation";

interface UpdateCustomerInfoProps {
  system: System;
  dataToBeUpdated: CustomerUpdateData;
  onUpdatedCustomerInfo: (data: CustomerUpdateData) => void;
  onSkipUpdateInfo: () => void;
  FC_SubmitCustomerInfo: (
    data: CustomerUpdateData,
    callback: (
      loading: boolean,
      res: { type: "SUCCESS" | "ERROR"; msg: string } | null
    ) => void
  ) => void;
  complaint_type: COMPLAINT_TYPE;
}

interface UpdateCustomerInfoState {
  age: string;
  complaint_key: COMPLAINT_TYPE;
  country_code: string;
  customer_id: string;
  district_code: string;
  email: string;
  gender: GenderEnum;
  names: string;
  phone_number: string;
  service_complaint_id: string;
  complaint_tracking_code: string;
  error: {
    target:
      | "main"
      | "age"
      | "country_code"
      | "customer_id"
      | "district_code"
      | "email"
      | "names"
      | "phone_number";
    msg: string;
  } | null;
  locations_loading: boolean;
  showSelectCountry: boolean;
  showSelectDistrict: boolean;
  loading: boolean;
  locations: LocationAPI | null;
  success: string;
}

class _UpdateCustomerInfo extends Component<
  UpdateCustomerInfoProps,
  UpdateCustomerInfoState
> {
  constructor(props: UpdateCustomerInfoProps) {
    super(props);
    this.state = {
      age: this.props.dataToBeUpdated.age,
      complaint_key: this.props.dataToBeUpdated.complaint_key,
      country_code: this.props.dataToBeUpdated.country_code,
      customer_id: this.props.dataToBeUpdated.customer_id,
      district_code: this.props.dataToBeUpdated.district_code,
      email: this.props.dataToBeUpdated.email,
      gender: this.props.dataToBeUpdated.gender,
      names: this.props.dataToBeUpdated.names,
      phone_number: this.props.dataToBeUpdated.phone_number,
      service_complaint_id: this.props.dataToBeUpdated.service_complaint_id,
      complaint_tracking_code:
        this.props.dataToBeUpdated.complaint_tracking_code,
      error: null,
      locations_loading: false,
      showSelectCountry: false,
      showSelectDistrict: false,
      loading: false,
      locations: null,
      success: "",
    };
  }
  GetLocations = () => {
    this.setState({ locations_loading: true });
    FC_GetLocationsDetails(
      (
        loading: boolean,
        res: {
          data: LocationAPI | null;
          type: "Error" | "Success";
          msg: string;
        } | null
      ) => {
        if (res?.type === "Success") {
          this.setState({
            locations_loading: loading,
            locations: res.data,
            error: null,
          });
        }
        if (res?.type === "Error") {
          this.setState({
            locations_loading: false,
            error: { target: "main", msg: res.msg },
          });
        }
      }
    );
  };
  UpdateCustomerInfoData = () => {
    if (this.state.names === "") {
      return this.setState({
        error: { target: "names", msg: "Please fill your names" },
      });
    }
    if (this.state.phone_number === "") {
      return this.setState({
        error: { target: "phone_number", msg: "Please fill your phone number" },
      });
    }
    //
    if (this.state.phone_number.length !== 10) {
      return this.setState({
        error: {
          target: "phone_number",
          msg: "Phone number should be 10 digits",
        },
      });
    }
    if (
      this.state.phone_number[0] !== "0" ||
      this.state.phone_number[1] !== "7"
    ) {
      return this.setState({
        error: {
          target: "phone_number",
          msg: "Phone number format should be 07XXXXXXXX",
        },
      });
    }
    if (this.state.email !== "" && !validateEmail(this.state.email)) {
      return this.setState({
        error: {
          target: "email",
          msg: "Invalid email, please verify the email",
        },
      });
    }
    const data: CustomerUpdateData = {
      age: this.state.age,
      complaint_key: this.state.complaint_key,
      country_code: this.state.country_code,
      customer_id: this.state.customer_id,
      district_code: this.state.district_code,
      email: this.state.email,
      gender: this.state.gender,
      service_complaint_id: this.state.service_complaint_id,
      names: this.state.names,
      phone_number: this.state.phone_number,
      complaint_tracking_code: this.state.complaint_tracking_code,
      complaint_lang: this.props.system.language,
      type: this.props.complaint_type,
      product_complaint_id: this.state.service_complaint_id,
      complaint_id: this.state.service_complaint_id,
    };
    this.props.FC_SubmitCustomerInfo(
      data,
      (
        loading: boolean,
        res: { type: "SUCCESS" | "ERROR"; msg: string } | null
      ) => {
        this.setState({ loading: loading });
        if (res?.type === "SUCCESS") {
          this.setState({ success: res.msg, loading: true, error: null });
          setTimeout(() => {
            this.props.onUpdatedCustomerInfo(data);
          }, 1000);
        }
        if (res?.type === "ERROR") {
          this.setState({
            error: { target: "main", msg: res.msg },
            loading: false,
            success: "",
          });
        }
      }
    );
  };
  componentDidMount(): void {
    if (this.state.locations === null) {
      this.GetLocations();
    }
  }
  render() {
    return (
      <div className="animate__animated animate__zoomIn">
        <div className="p-2 md:p-4">
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12 md:col-span-6">
              <Input
                title={GenerateLangText(
                  {
                    eng: `Full Name`,
                    fr: "Nom et prénom",
                    kiny: "Amazina yose",
                  },
                  this.props.system.language
                )}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ names: e.target.value, error: null })
                }
                disabled={this.state.loading}
                value={this.state.names}
                error={
                  this.state.error?.target === "names"
                    ? this.state.error.msg
                    : ""
                }
                type={"text"}
                className="bg-gray-100 font-bold"
                requiredStar={true}
              />
            </div>
            <div className="col-span-12 md:col-span-6 flex items-center justify-center gap-5">
              <Select
                title={GenerateLangText(
                  {
                    eng: `Gender`,
                    fr: "Genre",
                    kiny: "Igitsina",
                  },
                  this.props.system.language
                )}
                onChange={(e) =>
                  e.target.value !== "" &&
                  this.setState({
                    gender: e.target.value as GenderEnum,
                    error: null,
                  })
                }
                disabled={this.state.loading}
                value={this.state.gender}
                error={""}
                className="bg-gray-100 w-full font-bold"
                data={[
                  {
                    value: GenderEnum.MALE,
                    caption: "Male",
                  },
                  {
                    value: GenderEnum.FEMALE,
                    caption: "Female",
                  },
                ]}
              />
              <Select
                title={GenerateLangText(
                  {
                    eng: `What is your age range?`,
                    fr: "Quelle est votre classe d'âge?",
                    kiny: "Ufite imyaka ingahe?",
                  },
                  this.props.system.language
                )}
                onChange={(e) =>
                  this.setState({
                    age: e.target.value,
                    error: null,
                  })
                }
                disabled={this.state.loading}
                value={this.state.age}
                error={""}
                className="bg-gray-100 w-full font-bold"
                data={[
                  {
                    value: "18-25",
                    caption: "18-25",
                  },
                  {
                    value: "26-30",
                    caption: "26-30",
                  },
                  {
                    value: "30-35",
                    caption: "30-35",
                  },
                  {
                    value: "36-48",
                    caption: "36-48",
                  },
                  {
                    value: "49-More",
                    caption: "49-More",
                  },
                ]}
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <Input
                title={GenerateLangText(
                  {
                    eng: `Phone Number (07XXXXXXXX)`,
                    fr: "Numéro de téléphone (07XXXXXXXX)",
                    kiny: "Numero ya terefone (07XXXXXXXX)",
                  },
                  this.props.system.language
                )}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ phone_number: e.target.value, error: null })
                }
                disabled={this.state.loading}
                value={this.state.phone_number}
                error={
                  this.state.error?.target === "phone_number"
                    ? this.state.error.msg
                    : ""
                }
                type={"text"}
                className="bg-gray-100 font-bold"
                requiredStar={true}
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <Input
                title={GenerateLangText(
                  {
                    eng: `Email (example@email.com)`,
                    fr: "E-mail (example@email.com)",
                    kiny: "Imeri (example@email.com)",
                  },
                  this.props.system.language
                )}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ email: e.target.value, error: null })
                }
                disabled={this.state.loading}
                value={this.state.email}
                error={
                  this.state.error?.target === "email"
                    ? this.state.error.msg
                    : ""
                }
                type={"email"}
                className="bg-gray-100 font-bold"
              />
            </div>
            <div className="col-span-12">
              <div className="bg-gray-100 rounded-md">
                <div className="font-bold px-3 py-2 border-b mb-2">
                  {GenerateLangText(
                    {
                      eng: `Location of Residence`,
                      fr: "Lieu de résidence",
                      kiny: "Aho uherereye",
                    },
                    this.props.system.language
                  )}
                </div>
                <div className="grid grid-cols-12 gap-4 pb-3 px-3">
                  <div
                    className={`col-span-12 ${
                      this.state.country_code !== "" &&
                      this.state.country_code === "RWA"
                        ? "md:col-span-6"
                        : ""
                    }`}
                  >
                    <div className="relative">
                      <div>
                        {GenerateLangText(
                          {
                            eng: `Choose Country`,
                            fr: "Choisissez un pays",
                            kiny: "Hitamo Igihugu",
                          },
                          this.props.system.language
                        )}
                      </div>
                      {this.state.showSelectCountry === false && (
                        <div
                          onClick={() =>
                            this.state.loading === false &&
                            this.setState({ showSelectCountry: true })
                          }
                          className="flex flex-row items-center justify-between px-3 py-2 rounded-md bg-white border border-gray-300 w-full cursor-pointer"
                        >
                          {this.state.country_code === "" ? (
                            <span className="text-gray-400">
                              {GenerateLangText(
                                {
                                  eng: `Click to select country`,
                                  fr: "Cliquez pour sélectionner le pays",
                                  kiny: "Kanda kugira ngo uhitemo igihugu",
                                },
                                this.props.system.language
                              )}
                            </span>
                          ) : (
                            this.state.locations?.countries.find(
                              (itm) =>
                                itm.country_code === this.state.country_code
                            )?.country_name
                          )}
                          <div>
                            <MdOutlineKeyboardArrowDown className="text-2xl text-gray-400" />
                          </div>
                        </div>
                      )}
                      {this.state.locations_loading === true ? (
                        <div className="flex flex-row items-center gap-3 w-full">
                          <div>
                            <AiOutlineLoading3Quarters className="animate-spin text-3xl text-yellow-300" />
                          </div>
                          <div className="animate__animated animate__fadeIn animate__infinite">
                            {GenerateLangText(
                              {
                                eng: `Loading Locations`,
                                fr: "Emplacements de chargement",
                                kiny: "Tegereza gato",
                              },
                              this.props.system.language
                            )}
                          </div>
                        </div>
                      ) : (
                        this.state.showSelectCountry === true && (
                          <div className="absolute left-0 right-0">
                            <SelectCustom
                              loading={
                                this.state.loading === true ||
                                this.state.locations_loading
                              }
                              id={"country_code"}
                              title={"country_name"}
                              close={() =>
                                this.setState({ showSelectCountry: false })
                              }
                              data={
                                this.state.locations === null
                                  ? []
                                  : this.state.locations.countries
                              }
                              click={(data: {
                                country_code: string;
                                country_name: string;
                              }) => {
                                this.setState({
                                  country_code: data.country_code,
                                  showSelectCountry: false,
                                  district_code: "",
                                });
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  {/* Select District */}
                  {this.state.country_code !== "" &&
                    this.state.country_code === "RWA" && (
                      <div className={`col-span-12 md:col-span-6`}>
                        <div className="relative">
                          <div>
                            {GenerateLangText(
                              {
                                eng: `Choose District`,
                                fr: "Choisissez le quartier",
                                kiny: "Hitamo Akarere",
                              },
                              this.props.system.language
                            )}
                          </div>
                          {this.state.showSelectDistrict === false && (
                            <div
                              onClick={() =>
                                this.state.loading === false &&
                                this.setState({ showSelectDistrict: true })
                              }
                              className="flex flex-row items-center justify-between px-3 py-2 rounded-md bg-white border border-gray-300 w-full cursor-pointer"
                            >
                              {this.state.district_code === "" ? (
                                <span className="text-gray-400">
                                  {GenerateLangText(
                                    {
                                      eng: `Click to select district`,
                                      fr: "Cliquez pour sélectionner le quartier",
                                      kiny: "Kanda kugirango uhitemo akarere",
                                    },
                                    this.props.system.language
                                  )}
                                </span>
                              ) : (
                                this.state.locations?.districts.find(
                                  (itm) =>
                                    itm.district_code ===
                                    this.state.district_code
                                )?.district_name
                              )}
                              <div>
                                <MdOutlineKeyboardArrowDown className="text-2xl text-gray-400" />
                              </div>
                            </div>
                          )}
                          {this.state.locations_loading === true ? (
                            <div className="flex flex-row items-center gap-3 w-full">
                              <div>
                                <AiOutlineLoading3Quarters className="animate-spin text-3xl text-yellow-300" />
                              </div>
                              <div className="animate__animated animate__fadeIn animate__infinite">
                                {GenerateLangText(
                                  {
                                    eng: `Loading Locations`,
                                    fr: "Emplacements de chargement",
                                    kiny: "Tegereza gato",
                                  },
                                  this.props.system.language
                                )}
                              </div>
                            </div>
                          ) : (
                            this.state.showSelectDistrict === true && (
                              <div className="absolute left-0 right-0">
                                <SelectCustom
                                  loading={
                                    this.state.loading === true ||
                                    this.state.locations_loading
                                  }
                                  id={"district_code"}
                                  title={"district_name"}
                                  close={() =>
                                    this.setState({ showSelectDistrict: false })
                                  }
                                  data={
                                    this.state.locations === null
                                      ? []
                                      : this.state.locations.districts
                                  }
                                  click={(data: {
                                    district_code: string;
                                    district_name: string;
                                  }) => {
                                    this.setState({
                                      district_code: data.district_code,
                                      showSelectDistrict: false,
                                    });
                                  }}
                                />
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  {this.state.error?.target === "main" && (
                    <div className="col-span-12">
                      <Alert
                        alertType={AlertType.DANGER}
                        title={this.state.error.msg}
                        close={() =>
                          this.setState({ error: null, success: "" })
                        }
                      />
                    </div>
                  )}
                  {this.state.success !== "" && (
                    <div className="col-span-12">
                      <Alert
                        alertType={AlertType.SUCCESS}
                        title={this.state.success}
                        close={() =>
                          this.setState({ error: null, success: "" })
                        }
                      />
                    </div>
                  )}
                  <div className="col-span-12 flex flex-row items-center justify-end gap-2">
                    {/* {this.state.loading === false && (
                      <div
                        onClick={() => this.props.onSkipUpdateInfo()}
                        className="bg-white text-yellow-700 border border-yellow-500 hover:border-white h-10 flex items-center justify-center hover:bg-yellow-600 hover:text-white px-4 py-2 font-bold w-max rounded-md cursor-pointer"
                      >
                        {GenerateLangText(
                          {
                            eng: `Skip & Continue`,
                            fr: "Passer & Continuer",
                            kiny: "Simbuka & Ukomeze",
                          },
                          this.props.system.language
                        )}
                      </div>
                    )} */}
                    <div
                      onClick={() =>
                        this.state.loading === false &&
                        this.UpdateCustomerInfoData()
                      }
                      className="bg-green-600 text-white hover:bg-green-700 px-4 py-2 font-bold w-max rounded-md cursor-pointer flex items-center justify-center gap-2"
                    >
                      {this.state.loading === true && (
                        <div>
                          <AiOutlineLoading3Quarters className="animate-spin text-2xl text-yellow-300" />
                        </div>
                      )}
                      {this.state.loading === false ? (
                        <span>
                          {GenerateLangText(
                            {
                              eng: `Save & Continue`,
                              fr: "Enregistrer & Continuer",
                              kiny: "Ohereza amakuru & Ukomeze",
                            },
                            this.props.system.language
                          )}
                        </span>
                      ) : (
                        <span className="animate__animated animate__fadeIn animate__infinite">
                          {GenerateLangText(
                            {
                              eng: `Saving Changes...`,
                              fr: "Enregistrer les modifications...",
                              kiny: "Tegereza gato...",
                            },
                            this.props.system.language
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  system,
  services,
}: StoreState): { system: System; services: ServicesStore } => {
  return { system, services };
};

export const UpdateCustomerInfo = connect(mapStateToProps, {
  FC_SubmitCustomerInfo,
})(_UpdateCustomerInfo);
