import React, { Component, Fragment } from "react";
import { StoreState } from "../../reducers";
import {
  FC_ChangeSystemLanguage,
  FC_GetAllServices,
  GenderEnum,
  ServiceItem,
  ServicesStore,
  System,
} from "../../actions";
import { connect } from "react-redux";
import Container from "../../components/Container/Container";
import BackButton from "../../components/Fragments/BackButton";
import { Link, RouteComponentProps } from "react-router-dom";
import { COMPLAINT_TYPE, SelectComplaintType } from "./SelectComplaintType";
import { ServiceComplaintForm } from "./ServiceComplaintForm";
import { MdClose } from "react-icons/md";
import { UpdateCustomerInfo } from "../../components/UpdateCustomerInfo/UpdateCustomerInfo";
import { CustomerUpdateData } from "../../actions/customers.action";
import { ComplaintReferenceCode } from "./ComplaintReferenceCode";
import { ProductComplaintForm } from "./ProductComplaintForm";
import { GenerateLangText } from "../../utils/functions";
import {
  LanguageEnum,
  SelectService,
} from "../../components/SelectService/SelectService";
import Modal, { ModalSize, Themes } from "../../components/Modal/Modal";
import FilePreview from "../../components/FilePreview/FilePreview";

interface AppProps
  extends RouteComponentProps<{
    communication_channel_id: string | undefined;
    user_id: string | undefined;
    communication_channel: string | undefined;
  }> {
  system: System;
  services: ServicesStore;
  FC_ChangeSystemLanguage: (lang: LanguageEnum) => void;
  FC_GetAllServices: (callback: (loading: boolean) => void) => void;
}

interface AppState {
  loading: boolean;
  currentStep:
    | "COMPLAINT_TYPE"
    | "CHOOSE_SERVICE"
    | "COMPLAINT_FORM"
    | "CUSTOMER_INFO"
    | "SUBMITTED";
  selectedComplaintType: COMPLAINT_TYPE | null;
  selectedService: ServiceItem | null;
  submissionData: {
    customer_id: string;
    message: string;
    service_complaint_id: string;
    complaint_tracking_code: string;
  } | null;
  previewDoc: File | null;
}

class _App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      loading: false,
      currentStep: "COMPLAINT_TYPE",
      selectedComplaintType: COMPLAINT_TYPE.SERVICE,
      selectedService: null,
      submissionData: null,
      previewDoc: null,
    };
  }
  componentDidMount = () => {};
  render() {
    return (
      <Fragment>
        <div className="bg-gray-200 min-h-screen pb-20">
          <Container className=" pb-4">
            <div
              className={`bg-white rounded-xl mt-6 ${
                this.state.currentStep !== "COMPLAINT_TYPE"
                  ? "animate__animated animate__fadeInLeft"
                  : "animate__animated animate__fadeInLeft"
              }`}
            >
              <div
                className={`p-2 ${
                  this.state.currentStep !== "SUBMITTED" ? "border-b" : ""
                }`}
              >
                <div className="flex flex-row items-center gap-4 w-full">
                  {this.state.currentStep === "COMPLAINT_TYPE" ? (
                    <Link to={"/"}>
                      <BackButton
                        title={GenerateLangText(
                          {
                            eng: "Back Home",
                            fr: "Retour",
                            kiny: "Subira Inyuma",
                          },
                          this.props.system.language
                        )}
                        className="border rounded-lg hover:bg-primary-50 hover:text-primary-800 hover:border-primary-700"
                      />
                    </Link>
                  ) : (
                    this.state.currentStep !== "CUSTOMER_INFO" &&
                    this.state.currentStep !== "SUBMITTED" && (
                      <BackButton
                        title={GenerateLangText(
                          {
                            eng: "Back",
                            fr: "Retour",
                            kiny: "Subira Inyuma",
                          },
                          this.props.system.language
                        )}
                        className="border rounded-lg hover:bg-primary-50 hover:text-primary-800 hover:border-primary-700"
                        onClick={() => {
                          this.state.currentStep === "CHOOSE_SERVICE" &&
                            this.setState({ currentStep: "COMPLAINT_TYPE" });
                          this.state.currentStep === "COMPLAINT_FORM" &&
                            this.setState({ currentStep: "CHOOSE_SERVICE" });
                        }}
                      />
                    )
                  )}

                  <div className="font-bold text-xl w-full">
                    {this.state.currentStep === "COMPLAINT_TYPE" &&
                      GenerateLangText(
                        {
                          eng: "Submit query request",
                          fr: "Soumettre une demande de requête",
                          kiny: "Tanga Ikibazo/Icyifuzo cyawe",
                        },
                        this.props.system.language
                      )}
                    {this.state.currentStep === "CHOOSE_SERVICE" &&
                      `${GenerateLangText(
                        {
                          eng: "Choose",
                          fr: "Choisir",
                          kiny: "Hitamo",
                        },
                        this.props.system.language
                      )} ${
                        this.state.selectedComplaintType ===
                        COMPLAINT_TYPE.PRODUCT
                          ? GenerateLangText(
                              {
                                eng: "product",
                                fr: "produit",
                                kiny: "ibicuruzwa",
                              },
                              this.props.system.language
                            )
                          : GenerateLangText(
                              {
                                eng: "service",
                                fr: "service",
                                kiny: "serivisi",
                              },
                              this.props.system.language
                            )
                      } ${GenerateLangText(
                        {
                          eng: "you want to submit query on",
                          fr: "vous souhaitez soumettre une requête sur",
                          kiny: "ushaka kubazaho | gutangaho icyifuzo",
                        },
                        this.props.system.language
                      )}`}
                    {this.state.currentStep === "COMPLAINT_FORM" &&
                      `${GenerateLangText(
                        {
                          eng: "QUERY ON",
                          fr: "PLAINTE SUR",
                          kiny: "KUBAZA Ikibazo/Icyifuzo KURI",
                        },
                        this.props.system.language
                      )} ${this.state.selectedComplaintType}`}
                    {this.state.currentStep === "CUSTOMER_INFO" && (
                      <div className="px-3 py-2 w-full">
                        <div className="flex flex-row items-center justify-between gap-3 w-full">
                          <div className="font-bold text-2xl mb-1">
                            {GenerateLangText(
                              {
                                eng: "Personal information",
                                fr: "Informations personnelles",
                                kiny: "Amakuru yawe bwite?",
                              },
                              this.props.system.language
                            )}
                          </div>
                          {/* <div>
                            <div
                              onClick={() =>
                                this.setState({ currentStep: "SUBMITTED" })
                              }
                              className="h-10 w-10 flex items-center justify-center rounded-full cursor-pointer bg-red-50 hover:bg-red-100 text-red-700"
                            >
                              <MdClose className="text-3xl" />
                            </div>
                          </div> */}
                        </div>
                        <div className="text-base font-normal text-gray-500">
                          {GenerateLangText(
                            {
                              eng: `Your Addresses will be used by Rwanda FDA staff in case they want more details on your query. Then, the phone number or email will be used to send to you query feedback`,
                              fr: `Vos adresses seront utilisées par le personnel de la FDA du Rwanda au cas où ils voudraient plus de détails sur votre requête. Ensuite, le numéro de téléphone ou l'e-mail sera utilisé pour vous envoyer des commentaires sur la requête`,
                              kiny: `Aderesi zawe zizakoreshwa nabakozi ba Rwanda FDA mugihe bashaka ibisobanuro birambuye kubibazo | ibyifuzo byawe. Hanyuma, nimero ya terefone cyangwa imeri bizakoreshwa kugirango wohereze ibisubizo byikibazo cyangwa ibyifuzo`,
                            },
                            this.props.system.language
                          )}
                        </div>
                      </div>
                    )}
                    {this.props.match.params.communication_channel_id !==
                      undefined &&
                      this.props.match.params.communication_channel !==
                        undefined && (
                        <div className="bg-primary-700 text-white rounded-md px-2 text-sm w-max font-normal">
                          {this.props.match.params.communication_channel}
                        </div>
                      )}
                  </div>
                </div>
              </div>
              {/* Content */}
              {(this.state.currentStep === "COMPLAINT_TYPE" ||
                this.state.selectedComplaintType === null) && (
                <SelectComplaintType
                  selectedComplaintType={this.state.selectedComplaintType}
                  onSelectComplaintType={(complaintType: COMPLAINT_TYPE) =>
                    this.setState({
                      selectedComplaintType: complaintType,
                      currentStep: "CHOOSE_SERVICE",
                    })
                  }
                  language={this.props.system.language}
                />
              )}
              {this.state.currentStep === "CHOOSE_SERVICE" &&
                this.state.selectedComplaintType !== null && (
                  <SelectService
                    complaintType={this.state.selectedComplaintType}
                    onSelectService={(service: ServiceItem) => {
                      this.setState({
                        selectedService: service,
                      });
                      setTimeout(() => {
                        this.setState({ currentStep: "COMPLAINT_FORM" });
                      }, 600);
                    }}
                    selectedService={this.state.selectedService}
                    onSave={() =>
                      this.setState({ currentStep: "COMPLAINT_FORM" })
                    }
                    system={this.props.system}
                    services={this.props.services}
                    FC_ChangeSystemLanguage={this.props.FC_ChangeSystemLanguage}
                    FC_GetAllServices={this.props.FC_GetAllServices}
                    language={this.props.system.language}
                  />
                )}
              {this.state.currentStep === "COMPLAINT_FORM" &&
                this.state.selectedComplaintType === COMPLAINT_TYPE.SERVICE &&
                this.state.selectedService !== null && (
                  <ServiceComplaintForm
                    selectedService={this.state.selectedService}
                    onSubmitted={(data: {
                      customer_id: string;
                      message: string;
                      service_complaint_id: string;
                      complaint_tracking_code: string;
                    }) => {
                      this.setState({
                        submissionData: data,
                        currentStep: "CUSTOMER_INFO",
                      });
                    }}
                    language={this.props.system.language}
                    submitted_by={this.props.match.params.user_id ?? null}
                    communication_channel={{
                      communication_channel_id:
                        this.props.match.params.communication_channel_id ?? "1",
                      communication_channel:
                        this.props.match.params.communication_channel ?? "1",
                    }}
                    previewDoc={this.state.previewDoc}
                    setPreviewDoc={(file: File) =>
                      this.setState({ previewDoc: file })
                    }
                    onGoBack={() =>
                      this.setState({ currentStep: "COMPLAINT_TYPE" })
                    }
                  />
                )}
              {this.state.currentStep === "COMPLAINT_FORM" &&
                this.state.selectedComplaintType === COMPLAINT_TYPE.PRODUCT &&
                this.state.selectedService !== null && (
                  <ProductComplaintForm
                    selectedService={this.state.selectedService}
                    onSubmitted={(data: {
                      customer_id: string;
                      message: string;
                      product_complaint_id: string;
                      complaint_tracking_code: string;
                    }) => {
                      this.setState({
                        submissionData: {
                          complaint_tracking_code: data.complaint_tracking_code,
                          customer_id: data.customer_id,
                          message: data.message,
                          service_complaint_id: data.product_complaint_id,
                        },
                        currentStep: "CUSTOMER_INFO",
                      });
                    }}
                    language={this.props.system.language}
                    submitted_by={this.props.match.params.user_id ?? null}
                    communication_channel={{
                      communication_channel_id:
                        this.props.match.params.communication_channel_id ?? "1",
                      communication_channel:
                        this.props.match.params.communication_channel ?? "1",
                    }}
                    previewDoc={this.state.previewDoc}
                    setPreviewDoc={(file: File) =>
                      this.setState({ previewDoc: file })
                    }
                    onGoBack={() =>
                      this.setState({ currentStep: "COMPLAINT_TYPE" })
                    }
                  />
                )}
              {this.state.currentStep === "CUSTOMER_INFO" &&
                this.state.submissionData !== null &&
                this.state.selectedComplaintType !== null && (
                  <UpdateCustomerInfo
                    dataToBeUpdated={{
                      age: "",
                      complaint_key: this.state.selectedComplaintType,
                      country_code: "",
                      customer_id: this.state.submissionData.customer_id,
                      district_code: "",
                      email: "",
                      gender: GenderEnum.MALE,
                      names: "",
                      phone_number: "",
                      service_complaint_id:
                        this.state.submissionData.service_complaint_id,
                      complaint_tracking_code:
                        this.state.submissionData.complaint_tracking_code,
                      complaint_lang: this.props.system.language,
                      type: this.state.selectedComplaintType,
                      product_complaint_id:
                        this.state.submissionData.service_complaint_id,
                      complaint_id:
                        this.state.submissionData.service_complaint_id,
                    }}
                    onUpdatedCustomerInfo={(data: CustomerUpdateData) => {
                      this.setState({ currentStep: "SUBMITTED" });
                    }}
                    onSkipUpdateInfo={() =>
                      this.setState({ currentStep: "SUBMITTED" })
                    }
                    complaint_type={this.state.selectedComplaintType}
                  />
                )}
              {this.state.currentStep === "SUBMITTED" &&
                this.state.submissionData !== null && (
                  <ComplaintReferenceCode
                    submissionData={this.state.submissionData}
                    language={this.props.system.language}
                  />
                )}
            </div>
          </Container>
        </div>
        {this.state.previewDoc !== null && (
          <Modal
            backDrop={true}
            theme={Themes.default}
            close={() => this.setState({ previewDoc: null })}
            backDropClose={true}
            widthSizeClass={ModalSize.extraLarge}
            displayClose={false}
            padding={{
              title: undefined,
              body: undefined,
              footer: undefined,
            }}
          >
            <div className="p-0 rounded-md overflow-hidden">
              <FilePreview
                selectedFile={this.state.previewDoc}
                onClose={() => this.setState({ previewDoc: null })}
                isComponent={false}
              />
            </div>
          </Modal>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  system,
  services,
}: StoreState): { system: System; services: ServicesStore } => {
  return { system, services };
};

export const SubmitComplaint = connect(mapStateToProps, {
  FC_ChangeSystemLanguage,
  FC_GetAllServices,
})(_App);
